


























import Licensing from '@/services/api/Licensing';
import { Component } from 'vue-property-decorator';
import BaseUploader from '@/components/base/BaseUploader';

@Component
export default class LicenseUploader extends BaseUploader {
    public errorMsg: string = 'License report file too large for processing.';

    public resetFile(): void {
        (this.$refs.file as any).value = '';
    }

    public get isDisabledUploader(): boolean {
        return this.isDisabledUpload || ! this.fileName.length;
    }

    public uploadFile(): void {
        this.isDisabledUpload = true;
        this.uploadingInProgress = true;

        if (this.fileObj && this.fileObj.size) {
            const reader = new FileReader();
            reader.readAsText(this.fileObj);

            reader.onload = () => {
                if (reader.result && typeof reader.result === 'string') {
                    this.$store.commit('notificationState/setSpinnerMessage', 'Uploading...');

                    Licensing.uploadLicenseReport(reader.result).then(() => {
                        const result = {
                            success: true,
                            response: 'Successfully uploaded',
                            has_product_key: this.hasProductKey(reader.result as string),
                        };

                        this.$emit('uploaded', result);
                    }).catch(error => {
                        let result = null;

                        if ('response' in error) {
                            result = {
                                success: false,
                                response: error.response.data.error,
                            };
                        } else {
                            if (error.statusCode === 413) {
                                result = {
                                    success: false,
                                    response: error.statusMessage,
                                };
                            } else {
                                result = {
                                    success: false,
                                    response: 'Internal error',
                                };
                            }
                        }

                        this.$emit('uploaded', result);
                    }).finally(() => {
                        this.$store.commit('notificationState/clearSpinnerMessage');

                        this.resetState();
                    });
                }
            };
        } else {
            this.resetState();
            this.$store.commit('notificationState/setErrorMessage', 'Report file is not valid!');
        }
    }

    public hasProductKey(report: string): boolean {
        let isProductKey: boolean = true;

        for (const item of JSON.parse(report).license) {
            if (! item.productkeyhash) {
                isProductKey = false;

                break;
            }
        }

        return isProductKey;
    }
}
