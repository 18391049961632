



































































































































































































import Vue from 'vue';
import CommonRules from '@/rules/CommonRules';
import { Component } from 'vue-property-decorator';
import { DEFAULT_BODY } from '@/models/ProductModel';
import ProductKeyApi from '@/services/api/ProductKeyApi';
import ClipboardCopy from '@/components/common/ClipboardCopy.vue';
import { ProfileInterface } from '@/interfaces/ProfileStateInterface';

@Component({
    components: {
        ClipboardCopy,
    },
})
export default class ProductKeyDialog extends Vue {
    public dialog: boolean = false;
    public valid: boolean = false;
    public rules = CommonRules;
    public productBody: Record<string, string | number | null> = {};
    public loading: boolean = false;
    public productKeys: string[] = [];

    public init(): void {
        const profile: ProfileInterface = this.$store.getters['profile/getProfile'];

        Object.assign(this.productBody, { ...DEFAULT_BODY });

        this.productBody.email = profile.email;
        this.productBody.firstName = profile.first_name;
        this.productBody.lastName = profile.last_name;
        this.productBody.company = profile.company_name;
        this.productBody.phone = profile.phone_number;

        const form = (this.$refs.form as any);

        if (form) {
            form.resetValidation();
        }
    }

    public close(): void {
        this.dialog = false;
        this.productBody.serial = '';
        this.productKeys = [];
    }

    public async retrieveProductKey(): Promise<void> {
        this.loading = true;

        this.productKeys = [];

        try {
            const response = await ProductKeyApi.retrieveProductKey(this.productBody);
            const { mdcs } = response.data.data;

            if (mdcs.length) {
                this.findProductKeys(mdcs);
            }
        } catch (e) {
            this.$store.commit('notificationState/setErrorMessage', e.response.data.error);

            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    public findProductKeys(mdcs: Record<string, string>[]): void {
        [this.productBody.node1, this.productBody.node2].forEach(cvfsId => {
            if (cvfsId) {
                const found = mdcs.find(item => item.cvfsId === cvfsId);

                if (found) {
                    this.productKeys.push(found.productKey);
                }
            }
        });
    }
}
