




















import Vue from 'vue';
import VueJsonPretty from 'vue-json-pretty';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    components: {
        VueJsonPretty,
    },
})
export default class JsonViewerDialog extends Vue {
    @Prop({ required: true })
    public items!: any;
    public loading: boolean = true;
    public dialog: boolean = false;

    @Watch('items')
    public onItems(): void {
        this.loading = false;
    }

    public downloadJson(): void {
        this.$emit('downloadJson');
    }
}
