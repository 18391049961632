































import Vue from 'vue';
import SystemApi from '@/services/api/System';
import { Component, Prop } from 'vue-property-decorator';
import { DELETE_ADMIN_SYSTEM, DELETE_NOT_OWNER_SYSTEM, DELETE_OWNER_SYSTEM } from '@/constants/System';

@Component
export default class RemoveSystem extends Vue {
    public dialog: boolean = false;

    public processing: boolean = false;

    public isOwner: boolean = false;

    @Prop({ required: true, type: String })
    public systemUuid!: string;

    @Prop({ required: true, type: String })
    public name!: string;

    @Prop({ required: true, type: Boolean })
    public isRemoveAllowed!: boolean;

    public progressBarMessage: string = '';

    @Prop({ required: false, type: Boolean, default: false })
    public isIcon!: boolean;

    public close(): void {
        this.dialog = false;
    }

    public isRemoveDisabled(): boolean {
        return !this.isRemoveAllowed && !this.$store.getters['profile/isAdmin'];
    }

    public async setProgressMessage(): Promise<void> {
        try {
            let msg: string = DELETE_ADMIN_SYSTEM(this.name);

            if (! this.$store.getters['profile/isAdmin']) {
                const response: any = await SystemApi.isOwner(this.systemUuid);
                this.isOwner = response.data.success;
                msg = this.isOwner ? DELETE_OWNER_SYSTEM(this.name)
                    : DELETE_NOT_OWNER_SYSTEM(this.name, response.data.owner);
            }

            this.progressBarMessage = msg;

            this.dialog = true;
        } catch (e) {
            console.error(e);
        }
    }

    public removeFromAccount(): void {
        this.processing = true;
        this.progressBarMessage = 'Removing ' + this.name + ' from account....';

        SystemApi.removeFromAccount(this.systemUuid, this.isOwner).then(response => {
            this.processing = false;

            if (response.data.success) {
                this.dialog = false;
                this.$store.commit('notificationState/setSuccessMessage', 'System has been removed successfully');

                this.$emit('removed');
            } else {
                this.processing = false;
                this.dialog = false;
                this.$store.commit('notificationState/setErrorMessage', response.data.error);
            }
        }).catch(() => {
            this.processing = false;
            this.dialog = false;
            this.$store.commit('notificationState/setErrorMessage', 'Unable to remove system from account');
        });
    }
}
