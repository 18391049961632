





















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GatewayLicenseSummaryInterface, LicenseSummaryInterface } from '@/interfaces/LicenseInterface';

@Component
export default class LicenseSummary extends Vue {
    @Prop({ required: true })
    public licenseSummary!: GatewayLicenseSummaryInterface;
    @Prop({ required: true, type: Boolean, default: false })
    public dialog: boolean = false;

    public getLicenseDataByType(gatewayLicenses: LicenseSummaryInterface[], type: string): string {
        const license: LicenseSummaryInterface|undefined = gatewayLicenses.find(item => item.type === type);

        if (license) {
            return `Reported: ${license.reported} Total: ${license.total}`;
        }

        return 'Total 0';
    }

    public getGateway(index: string): string {
        return index === 'null' ? 'Uploaded By User' : index;
    }
}

