





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { License } from '@/interfaces/LicenseInterface';
import LicenseTransformer from '@/transformers/LicenseTransformer';

@Component
export default class LicensingDetails extends Vue {
    @Prop({ default: [] })
    public details: any;

    public items: any[] = [];
    public subColumns: any[] = [
        { value: 'name', text: 'License Name' },
        { value: 'type', text: 'License Type' },
        { value: 'status', text: 'Reported Status' },
        { value: 'authorized', text: 'License Authorization' },
        { value: 'authorized_usage', text: 'Authorized Capacity/Nodes', sortable: false },
        { value: 'usage', text: 'Current/Configured Usage', sortable: false },
    ];

    public authorizedClasses(item: any): object {
        return {
            'not-reported-row': item.row_color === 'amber',
            'not-authorized-row': item.row_color === 'red',
        };
    }

    public getCurrentUsageTooltip(item: any): string {
        if (item.original_name === 'fs' && item.type === 'Capacity') {
            const usage: string = this.numberWithCommas(item.usage_bytes);
            const configured: string = this.numberWithCommas(item.configured_bytes);

            if (! item.usage_bytes) {
                return `0 / ${configured} bytes`;
            }

            return `${usage} bytes / ${configured} bytes`;
        }

        if (item.type === 'Capacity') {
            return Number(item.configured_bytes) ? `${this.numberWithCommas(item.configured_bytes)} bytes` : '';
        }

        return '';
    }

    public getAuthorizedUsageTooltip(item: any): string {
        const tooltip: string = Number(item.authorized_bytes) ?
            `${this.numberWithCommas(item.authorized_bytes)} bytes` : '';

        if (item.type === 'Capacity') {
            return tooltip;
        }

        return '';
    }

    public numberWithCommas(num: number|string): string {
        return Number(num) ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
    }

    public getAuthorizedUsageString(item: any): string {
        let { authorized_usage }: any = item;

        if (Number.isInteger(authorized_usage) && item.type === 'Capacity') {
            authorized_usage = authorized_usage.toFixed(2);
        }

        if (authorized_usage !== 0 && authorized_usage !== null && item.original_name === 'totalNodes') {
            return authorized_usage > 1 ? `${authorized_usage} Nodes` : `${authorized_usage} Node`;
        }

        return authorized_usage ? `${authorized_usage} ${item.authorized_usage_unit}` : 'N/A';
    }

    public getItemUsageString(item: License): string {
        let { usage }: any = item;

        if (! usage) {
            return '0';
        }

        if (item.type === 'Capacity') {
            usage = usage.toFixed(2);
        }

        return `${usage} ${item.usage_unit}`;
    }

    public getConfiguredUsageString(item: License): string {
        let { configured_usage }: any = item;

        if (configured_usage && item.type === 'Capacity') {
            configured_usage = configured_usage.toFixed(2);
        }

        if (configured_usage !== 0 && item.original_name === 'totalNodes') {
            return configured_usage > 1 ? `${configured_usage} Nodes` : `${configured_usage} Node`;
        }

        return configured_usage ? `${configured_usage} ${item.configured_usage_unit}` : 'N/A';
    }

    public getCurrentUsage(item: License): string {
        if (! item.status) {
            return 'N/A';
        }

        if (item.original_name === 'fs' && item.type === 'Capacity') {
            return `${this.getItemUsageString(item)} / ${this.getConfiguredUsageString(item)}`;
        }

        return this.getConfiguredUsageString(item);
    }

    public getStatus(featureStatus: boolean): string {
        return featureStatus ? 'In Use' : 'Not In Use';
    }

    public getAuthorized(item: License): string {
        if (LicenseTransformer.isSummaryLicense(item.original_name, item.type)) {
            return item.authorized ? 'Authorized' : 'Not Authorized';
        }

        return item.authorized ? 'Authorized' : 'N/A';
    }
}
