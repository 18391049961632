









import Vue from 'vue';
import { COMPLIANCE_ALERT } from '@/constants/License';
import { Component, Prop } from 'vue-property-decorator';
import { LicenseSystemInterface } from '@/interfaces/LicenseInterface';

@Component
export default class WarningShield extends Vue {
    @Prop({ required: true })
    public report!: LicenseSystemInterface;
    public color: string = '';
    public message: string = 'Click to view alerts for this registration ID';

    public created(): void {
        if (! this.report.product_hash_key) {
            this.message = COMPLIANCE_ALERT;
            this.color = 'red';

            return;
        }

        for (const license of this.report.licenses) {
            if (license.row_color === 'red') {
                this.color = license.row_color;
                break;
            }

            if (license.row_color === 'amber') {
                this.color = license.row_color;
            }
        }
    }

    public addToExpand(): void {
        this.$emit('addToExpand', this.report);
    }
}
