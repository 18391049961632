var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.processing)?_c('v-container',{staticClass:"pa-0 ma-0",staticStyle:{"width":"100%"},attrs:{"id":"main-content","fluid":""}},[(!_vm.processing)?_c('div',{staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[(! _vm.systemIdentifier)?_c('v-text-field',{staticClass:"input-slot-dark compact-form",class:{ 'input-slot-light': ! _vm.$vuetify.theme.dark },attrs:{"filled":"","prepend-inner-icon":"search","label":"Search","clearable":"","dense":"","color":_vm.$store.state.inputHL},on:{"paste":_vm.onPaste},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),(_vm.isCustomersOnlyAvailable())?_c('v-col',{staticClass:"pr-0 pl-0 pt-0",attrs:{"md":"1"}},[_c('v-switch',{attrs:{"label":"Only Customers"},model:{value:(_vm.onlyCustomers),callback:function ($$v) {_vm.onlyCustomers=$$v},expression:"onlyCustomers"}})],1):_vm._e(),_c('v-col',{staticClass:"pt-5 text-right",attrs:{"offset-md":"1"}},[(_vm.$store.getters['siteConfigState/isProductKeyFeatureEnabled'])?_c('product-key-dialog'):_vm._e(),(! _vm.systemIdentifier)?_c('v-btn',{staticClass:"ml-2 mr-2",attrs:{"color":"primary"},on:{"click":_vm.expandOrCollapse}},[_vm._v(_vm._s(_vm.isExpandedAll ? 'Collapse All' : 'Expand All'))]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(! _vm.systemIdentifier)?_c('v-btn',_vm._g({attrs:{"color":"primary"},on:{"click":function($event){return _vm.showUpload()}}},on),[_vm._v("UPLOAD SN7 LICENSE REPORT")]):_vm._e()]}}],null,false,362140781)},[_c('span',[_vm._v("Upload a license report exported from a StorNext 7 system")])])],1)],1)],1):_vm._e(),_c('v-toolbar',{staticClass:"header-toolbar",class:{ 'card-header': this.$vuetify.theme.dark, 'card-header-light': ! _vm.$vuetify.theme.dark }},[_c('v-icon',{staticClass:"header-icon"},[_vm._v("mdi-shield")]),_c('v-toolbar-title',{staticClass:"header-title"},[_vm._v("Licensing Report")]),_c('div',{staticClass:"text-xs-center pb-6"},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":0,"bottom":"","right":"","open-on-hover":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"header-edit-icon-imported"},on),[_vm._v("mdi-dots-vertical")])]}}],null,false,3658968997)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.setMode(true)}}},[_c('v-list-item-title',[_vm._v("Summary Mode")]),(_vm.isSummary)?_c('v-list-item-icon',{staticClass:"check-icon"},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()],1),(_vm.isQuantumUser)?_c('v-list-item',{on:{"click":function($event){return _vm.setMode(false)}}},[_c('v-list-item-title',[_vm._v("Detail Mode")]),(!_vm.isSummary)?_c('v-list-item-icon',{staticClass:"check-icon"},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()],1):_vm._e(),(! _vm.systemIdentifier)?_c('v-list-item',{on:{"click":function($event){_vm.isUploadStatus = true}}},[_c('v-list-item-title',[_vm._v("Last Upload Summary")])],1):_vm._e()],1)],1)],1),_c('license-summary',{attrs:{"license-summary":_vm.licenseSummary,"dialog":_vm.isUploadStatus},on:{"hideUploadStatus":function($event){_vm.isUploadStatus = false}}})],1)],1),_c('v-spacer'),_c('v-card',{staticClass:"dash-chart-container-spacer",attrs:{"tile":""}},[_c('v-data-table',{staticClass:"elevation-0 elevation-1",attrs:{"headers":_vm.columns,"search":_vm.search,"items":_vm.getFilteredReports(),"options":_vm.pagination,"expanded":_vm.expanded,"single-expand":true,"item-key":"system_uuid","sort-by":"last_report","custom-sort":_vm.customSort,"must-sort":"","sort-desc":"","footer-props":{
                'items-per-page-options': _vm.rowsPerPageItems
            }},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
            var header = ref.header;
return undefined}},{key:"item.is_alert",fn:function(ref){
            var item = ref.item;
return [_c('warning-shield',{attrs:{"report":item},on:{"addToExpand":_vm.addToExpand}})]}},{key:"item.registration_id",fn:function(ref){
            var item = ref.item;
return [_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-start":"","row":"","flex-nowrap":""}},[_c('div',{staticClass:"pr-3"},[_vm._v(_vm._s(item.registration_id))])])],1)]}},{key:"item.total_license_nodes",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getTotalNodes(item.total_license_nodes)))])]}},{key:"item.last_report",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.last_report))])]}},{key:"item.last_upload",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.last_upload))])]}},{key:"item.license_last_quantum_license_info_sync_time",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.license_last_quantum_license_info_sync_time))])]}},{key:"item.expires",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.expired_date))])]}},{key:"item.owner",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.owner ? item.owner : 'N/A'))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-start":"","row":"","flex-nowrap":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addToExpand(item, false)}}},[(!_vm.isExpandedRow(item))?_c('v-icon',[_vm._v("mdi-arrow-expand-down")]):_vm._e(),(_vm.isExpandedRow(item))?_c('v-icon',[_vm._v("mdi-arrow-collapse-up")]):_vm._e()],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","href":_vm.getCloudBaseAnalyticsLink(item.cba_id),"target":"_blank"}},on),[_c('v-icon',[_vm._v("mdi-chart-areaspline")])],1)]}}],null,true)},[_vm._v("Cloud-Based Analytics")]),_c('v-menu',{attrs:{"close-on-content-click":true,"bottom":"","left":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var menu = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},menu),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-card',[_c('v-list',{staticClass:"text-left"},[_c('v-list-item',{on:{"click":function($event){return _vm.showBlock("licenses", item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Licenses")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.showBlock("system", item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("System Information")])],1)],1),_c('sync-report',{attrs:{"report":item,"items":_vm.items,"isSummary":_vm.isSummary}}),_c('json-viewer-dialog',{attrs:{"items":_vm.reportJson},on:{"downloadJson":function($event){return _vm.downloadReportJson(item.system_uuid)}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-list-item',{on:{"click":function($event){return _vm.showReportJson(item.system_uuid)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Show Report JSON")])],1)],1)]},proxy:true}],null,true)})],1)],1)],1),(_vm.isRemoveAvailable)?_c('remove-system',{attrs:{"is-icon":"","system-uuid":item.system_uuid,"name":item.license_system_serial_number,"is-remove-allowed":item.is_system_remove_allowed},on:{"removed":_vm.getLicensingReports}}):_vm._e()],1)],1)]}},{key:"expanded-item",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":9}},[(_vm.states[item.system_uuid] && _vm.states[item.system_uuid].system)?[_c('licensing-info-dialog',{attrs:{"system":item}})]:_vm._e(),(_vm.states[item.system_uuid] && _vm.states[item.system_uuid].storage)?[_c('block-storage-dialog')]:_vm._e(),(_vm.states[item.system_uuid] && _vm.states[item.system_uuid].licenses)?[_c('licensing-details',{attrs:{"details":item.licenses}})]:_vm._e()],2)]}}],null,false,714739803)})],1),_c('v-dialog',{attrs:{"value":_vm.isShowUpload,"persistent":"","max-width":"500px"}},[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v("Upload License Report")])]),_c('v-card-text',[_vm._v("Upload your StorNext license report. The license file must be in plain text format and contain a JSON string."),_c('license-uploader',{staticClass:"pa-4",on:{"uploaded":_vm.reportUploaded,"cancel":function($event){_vm.isShowUpload = !_vm.isShowUpload}}})],1)],1)],1),_c('v-dialog',{attrs:{"width":"400px","persistent":""}},[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v("Updating")])]),_c('v-card-text',[_vm._v("Updating information for this system from Quantum databases. Please wait..."),_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1),_c('notification-dialog',{attrs:{"msg":_vm.notificationMsg}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }