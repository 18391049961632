










import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class NotificationDialog extends Vue {
    @Prop({ required: true, type: String })
    public msg!: string;
    public dialog: boolean = false;

    @Watch('msg')
    public onMsgChanged(msg: string) {
        if (msg.length) {
            this.dialog = true;
        }
    }
}
