import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import HTMLInputEventInterface from '@/interfaces/HTMLInputEventInterface';

@Component
export default class BaseUploader extends Vue {
    public fileName: string = '';
    public response: string = '';
    public isError: boolean = false;
    public maxFileSize: number = 512000;
    public isDisabledUpload: boolean = true;
    public fileObj: File | Blob | null = null;
    public uploadingInProgress: boolean = false;
    public errorMsg: string = 'File must be less than 500k';

    public cancel(): void {
        this.$emit('cancel');
        this.fileName = '';
        this.isError = false;
    }

    public pickFile(refs: any): void {
        const e = refs.file as HTMLElement;

        e.click();
    }

    public onFilePicked(e: HTMLInputEventInterface): void {
        const files = e.target.files;
        this.fileName = '';
        this.fileObj = null;
        this.isError = false;

        if (files && files[0] !== undefined) {
            if (files[0].size > this.maxFileSize) {
                this.isError = true;
            } else {
                this.fileObj = files[0];
                this.isDisabledUpload = false;
            }

            this.fileName = files[0].name;
        }
    }

    public resetState(): void {
        this.isDisabledUpload = false;
        this.uploadingInProgress = false;
        this.fileName = '';
        this.isError = false;
    }
}
