export const DEFAULT_BODY = {
    serial: '',
    node1: '',
    node2: '',
    address1: null,
    address2: null,
    address3: null,
    address4: null,
    city: '',
    country: null,
    postalCode: '',
    state: null,
    resellerName: null,
    jobTitle: '',
};
