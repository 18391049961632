








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ClipboardCopy extends Vue {
    @Prop({ required: true, type: String })
    public value!: string;

    @Prop({ required: false, type: String, default: () => '' })
    public classes!: string;

    public copy(): void {
        navigator.clipboard.writeText(this.value);
    }
}
