



























































































































































































import Vue from 'vue';
import DateUtil from '@/utils/DateUtil';
import SyncReport from './SyncReport.vue';
import StringUtil from '@/utils/StringUtil';
import LicensingDetails from './LicensingDetails.vue';
import { COMPLIANCE_ALERT } from '@/constants/License';
import LicensingApi from '../../services/api/Licensing';
import LicensingInfoDialog from './LicensingInfoDialog.vue';
import RemoveSystem from '@/components/common/RemoveSystem.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ProfileInterface } from '@/interfaces/ProfileStateInterface';
import LicenseTransformer from '../../transformers/LicenseTransformer';
import JsonViewerDialog from '@/components/common/JsonViewerDialog.vue';
import WarningShield from '@/components/LicensingReport/WarningShield.vue';
import NotificationDialog from '@/components/common/NotificationDialog.vue';
import LicenseSummary from '@/components/LicensingReport/LicenseSummary.vue';
import LicenseUploader from '@/components/LicensingReport/LicenseUploader.vue';
import ProductKeyDialog from '@/components/LicensingReport/ProductKeyDialog.vue';
import { GatewayLicenseSummaryInterface, LicenseSystemInterface } from '@/interfaces/LicenseInterface';

@Component({
    components: {
        SyncReport,
        RemoveSystem,
        WarningShield,
        LicenseSummary,
        LicenseUploader,
        LicensingDetails,
        JsonViewerDialog,
        ProductKeyDialog,
        NotificationDialog,
        LicensingInfoDialog,
    },
})
export default class ReportList extends Vue {
    public search: string = '';
    @Prop({ type: String, required: false })
    public systemIdentifier!: string;
    @Prop({ required: false, type: Boolean, default: true })
    public isRemoveAvailable!: boolean;
    public isUploadStatus: boolean = false;
    public onlyCustomers: boolean = false;
    public columns: any[] = [
        { value: 'is_alert', text: '' },
        { value: 'registration_id', text: 'Registration ID', sortable: Boolean(!this.systemIdentifier), width: '150px'},
        { text: 'Last Report', sortable: Boolean(!this.systemIdentifier), value: 'last_report', filterable: false },
        { text: 'Last Upload', sortable: Boolean(!this.systemIdentifier), value: 'last_upload', filterable: false },
        {
            text: 'Total Nodes', sortable: Boolean(!this.systemIdentifier),
            value: 'total_license_nodes', filterable: false,
        },
        {
            text: 'Last Quantum Update',
            sortable: Boolean(!this.systemIdentifier),
            filterable: false,
            value: 'license_last_quantum_license_info_sync_time',
        },
        {
            text: 'Expires',
            sortable: false,
            filterable: false,
            value: 'expired_date',
        },
        {
            text: 'Owner',
            value: 'owner',
            sortable: true,
        },
        {
            text: 'Actions',
            sortable: false,
            filterable: false,
            value: 'actions',
        },
    ];
    public items: any[] = [];
    public expanded: any[] = [];
    public states: any = {};
    public expand: boolean = false;
    public pagination: any = {};
    public rowsPerPageItems: any[] = [
        10, 20, 50,
        { text: 'All', value: -1 },
    ];
    public isSummary: boolean = true;
    public licenseSummary: GatewayLicenseSummaryInterface = {};
    public processing: boolean = false;
    public isShowUpload: boolean = false;
    public complianceMsg: string = 'Click to view alerts for this registration ID';
    public reportJson: any = [];
    public isExpandedAll: boolean = false;
    public notificationMsg: string = '';
    public report: LicenseSystemInterface = {} as LicenseSystemInterface;

    public customSort(
        items: LicenseSystemInterface[], sortBy: string[], sortDesc: boolean[],
    ): LicenseSystemInterface[] {
        const sortIndex: string = sortBy[0];

        items.sort((a: any, b: any): any => {
            if (sortIndex === 'last_report') {
                return this.sortCondition(a, b, 'unix_last_report_time', sortDesc[0]);
            } else if (sortIndex === 'license_last_quantum_license_info_sync_time') {
                return this.sortCondition(a, b, 'unix_quantum_update_time', sortDesc[0]);
            } else if (sortIndex === 'last_upload') {
                return this.sortCondition(a, b, 'unix_last_upload', sortDesc[0]);
            } else {
                return this.sortCondition(a, b, sortIndex, sortDesc[0]);
            }
        });

        return items;
    }

    public onPaste() {
        this.pagination.page = 1;
    }

    public isCustomersOnlyAvailable(): boolean {
        return this.$store.getters['profile/isAdmin'] || this.$store.getters['profile/isLicenseAdmin'];
    }

    public getFilteredReports(): LicenseSystemInterface[] {
        let reports: LicenseSystemInterface[] = this.items;

        if (this.onlyCustomers) {
            reports = reports.filter(item => item.owner && !item.owner.includes('@quantum'));
        }

        return reports;
    }

    @Watch('search')
    public onSearchChanged(): void {
        this.search = this.search.replace(/\s+/g, '');
    }

    public get isQuantumUser(): boolean {
        const user: ProfileInterface = this.$store.getters['profile/getProfile'];

        if (user.email) {
            return StringUtil.searchString(user.email, 'quantum.com') !== -1;
        }

        return false;
    }

    public downloadReportJson(systemUuid: string): void {
        LicensingApi.downloadRecentReportJson(systemUuid).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${systemUuid}.json`);
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            this.$store.commit('notificationState/setErrorMessage', 'Can\'t get JSON');
        });
    }

    public sortCondition(a: any, b: any, sortIndex: string, isDesc: boolean): number {
        if (! isDesc) {
            if (a[sortIndex] === null) {
                return 1;
            }

            if (b[sortIndex] === null) {
                return -1;
            }

            return a[sortIndex] < b[sortIndex] ? -1 : 1;
        }

        if (b[sortIndex] === null) {
            return 1;
        }

        if (a[sortIndex] === null) {
            return -1;
        }

        return b[sortIndex] < a[sortIndex] ? -1 : 1;
    }

    public showReportJson(systemUuid: string): void {
        LicensingApi.getRecentReportJson(systemUuid).then(response => {
            this.reportJson = response.data;
        }).catch(error => {
            this.$store.commit('notificationState/setErrorMessage', 'Can\'t get JSON');
        });
    }

    public getLicensingReports(): void {
        // Need this to reload table component on the upload license file to invoke warning shield update
        this.items = [];

        this.$store.commit('notificationState/setSpinnerMessage', 'Getting reports...');

        if (! this.systemIdentifier) {
            this.$store.dispatch('licenseModule/getLicenses', { isSummary: this.isSummary }).then(reports => {
                this.items = reports;

                this.setActiveStates(this.items);
            }).catch(error => {
                console.error(error);
                if (error.response.status === 404) {
                    return;
                }

                this.$store.commit('notificationState/setErrorMessage', 'Unable to get reports!');
            }).finally(() => {
                this.$store.commit('notificationState/clearSpinnerMessage');
            });

            return;
        }

        this.$store.dispatch(
            'licenseModule/getLicensesBySystemID', { isSummary: this.isSummary, systemID:  this.systemIdentifier },
        ).then(reports => {
            this.items = reports;

            this.setActiveStates(this.items);
        }).catch(error => {
            console.error(error);
            this.$store.commit('notificationState/setErrorMessage', 'Unable to get reports!');
        }).finally(() => {
            this.$store.commit('notificationState/clearSpinnerMessage');
        });
    }

    public reportUploaded(result: { response: string; success: boolean; has_product_key: boolean }): void {
        if (result.success) {
            this.$store.commit('notificationState/setSuccessMessage', result.response);
            this.isShowUpload = false;

            if (! result.has_product_key) {
                this.notificationMsg = COMPLIANCE_ALERT;
                this.$store.dispatch('authState/setCompliance');
            }

            this.getLicensingReports();
            this.getLicenseSummary();
        } else {
            this.$store.commit('notificationState/setErrorMessage', result.response);
        }
    }

    public getCloudBaseAnalyticsLink(cbaID: null|string): string {
        const link: string = 'https://insight.quantum.com/';

        return cbaID ? `${link}appliance/${cbaID}` : link;
    }

    public showUpload(): void {
        this.isShowUpload = true;
    }

    public getTotalNodes(nodes: number): string {
        return nodes > 1 ? `${nodes} Nodes` : `${nodes} Node`;
    }

    public getLicenseSummary(): void {
        LicensingApi.getLicenseSummary().then(response => {
            this.licenseSummary = LicenseTransformer.getLicenseSummaryTransformed(response.data.licenseSummary);
        }).catch(error => {
            console.error(error);
        });
    }

    public setActiveStates(items: any[]): void {
        items.forEach((item: any) => {
            this.states[item.system_uuid] = {};
            this.states[item.system_uuid].licenses = true;
        });
    }

    public changeMode(): void {
        this.isSummary = !this.isSummary;
        this.getLicensingReports();
    }

    public showBlock(block: string, item: LicenseSystemInterface): void {
        this.states = {};
        this.expanded = [];
        this.addToExpand(item, true);
        this.states[item.system_uuid] = this.states[item.system_uuid] || {};
        this.states[item.system_uuid][block] = true;
    }

    public setMode(v: boolean) {
        this.isSummary = v;
        this.getLicensingReports();
    }

    public addToExpand(item: LicenseSystemInterface, leaveExpanded: boolean = false): void {
        const index: number = this.expanded.findIndex((license: LicenseSystemInterface) =>
            license.system_uuid === item.system_uuid,
        );

        if (leaveExpanded) {
            if (index === -1) {
                this.expanded.push(item);
            }
        } else {
            if (this.states[item.system_uuid] && this.states[item.system_uuid].licenses) {
                if (index !== -1) {
                    this.expanded.splice(index, 1);
                } else {
                    this.expanded.push(item);
                }
            } else {
                this.expanded = [];
                // This is to prevent displaying licenses on system info hiding click
                if (this.states[item.system_uuid] && this.states[item.system_uuid].system) {
                    this.states = {};
                    return;
                }

                this.states = {};
                this.expanded.push(item);
                this.states[item.system_uuid] = {};
                this.states[item.system_uuid].licenses = true;
            }
        }
    }

    public expandOrCollapse(): void {
        if (this.isExpandedAll) {
            this.expanded = [];
            this.isExpandedAll = false;
        } else {
            this.isExpandedAll = true;
            this.states = {};
            this.expanded = [];

            this.items.forEach((item: LicenseSystemInterface) => {
                this.expanded.push(item);
                this.states[item.system_uuid] = {};
                this.states[item.system_uuid].licenses = true;
            });
        }
    }

    public isExpandedRow(item: LicenseSystemInterface) {
        const index: number = this.expanded.findIndex((license: LicenseSystemInterface) =>
            license.system_uuid === item.system_uuid,
        );

        return index !== -1;
    }

    public mounted(): void {
        this.$store.dispatch('licenseModule/getApproachingCoefficients').finally(() => {
            this.getLicensingReports();
            this.getLicenseSummary();
        });

        setInterval(() => {
            const currentDateTime: number = (new Date()).getTime();

            this.items.forEach((item: LicenseSystemInterface) => {
                if (DateUtil.diffInHours(currentDateTime, item.unix_quantum_update_time)) {
                    item.can_update = true;
                }
            });
        }, 180000);
    }
}
