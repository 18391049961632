/*eslint-disable*/
export const DELETE_OWNER_SYSTEM = (system: string) => {
    return `Removing ${system} will remove this appliance from the portal.
            This will remove ${system} from your view and from all other users authorized to view this appliance.`;
};

export const DELETE_NOT_OWNER_SYSTEM = (system: string, email: string) => {
    if (! email) {
        return `This will remove ${system} from only your portal view.`
    }

    return `Appliance ${system} is owned by user ${email}.
            This will remove ${system} from only your portal view.`;
};

export const DELETE_ADMIN_SYSTEM = (system: string) => {
    return `Removing ${system} will remove this appliance from the portal.`;
};
