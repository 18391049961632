









import Vue from 'vue';
import DateUtil from '@/utils/DateUtil';
import LicensingApi from '../../services/api/Licensing';
import { Component, Prop } from 'vue-property-decorator';
import LicenseTransformer from '@/transformers/LicenseTransformer';
import { ProfileInterface } from '@/interfaces/ProfileStateInterface';
import { LicenseSystemInterface } from '@/interfaces/LicenseInterface';
import { LICENSE_SYNC, LICENSE_SYNC_DISABLED } from '@/models/ReportModel';

@Component
export default class SyncReport extends Vue {
    @Prop({ required: true, type: Object })
    public report!: LicenseSystemInterface;
    @Prop({ required: true, type: Boolean })
    public isSummary!: boolean;
    @Prop({ required: true, type: Array })
    public items!: LicenseSystemInterface[];
    public tooltipText: string = LICENSE_SYNC;

    public created(): void {
        const currentDateTime: number = (new Date()).getTime();

        if (! DateUtil.diffInHours(currentDateTime, this.report.unix_quantum_update_time)) {
            this.report.can_update = false;
            this.tooltipText = LICENSE_SYNC_DISABLED;
        } else {
            this.report.can_update = true;
        }
    }

    public updateSync(): void {
        if (! this.report.can_update) {
            return;
        }

        this.$store.commit('notificationState/setOverlay', false);

        this.$store.commit(
            'notificationState/setSpinnerMessage',
            'Updating information for this system from Quantum databases. Please wait...',
        );

        LicensingApi.updateSync(this.report.system_uuid).then(response => {
            const report: LicenseSystemInterface = LicenseTransformer.getTransformer(
                [response.data.report], this.isSummary,
            )[0];

            const index: number = this.items.findIndex(
                (item: LicenseSystemInterface) => item.system_uuid === report.system_uuid,
            );

            if (index !== -1) {
                Object.assign(this.report, report);
            }
        }).catch(error => {
            const lastUpdatedDate: number = error.response.data.lastUpdatedDate;

            this.$store.commit(
                'notificationState/setErrorMessage',
                error.response.data.error,
            );

            if (lastUpdatedDate) {
                this.setLastUpdateDate(lastUpdatedDate);
            }
        }).finally(() => {
            this.report.can_update = false;
            this.$store.commit('notificationState/clearSpinnerMessage');
            this.$store.commit('notificationState/setOverlay');
        });
    }

    private setLastUpdateDate(date: number): void {
        const profile: ProfileInterface = this.$store.getters['profile/getProfile'];
        const hourFormat: string = profile.hour_format === 24 ? 'HH:mm:ss' : 'hh:mm:ss A';
        const dateFormat: string = `ddd MMM DD ${hourFormat} z YYYY`;
        let tz: string = '';

        if (profile.time_zone) {
            tz = profile.time_zone;
        }

        this.report.license_last_quantum_license_info_sync_time = DateUtil.millisecondsToDateFormat(
            date * 1000, dateFormat, tz,
        );
    }
}
