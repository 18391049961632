










































import Vue from 'vue';
import { SystemInterface } from '../../interfaces/SystemInterface';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class LicensingInfoDialog extends Vue {
    @Prop({ required: true, type: Object })
    public system!: any;
}
