import store from '@/store';
import axios from '@/services/api/Common';
import { AxiosPromise, AxiosRequestConfig } from 'axios';

export default class ProductKeyApi {
    private static host: string = store.getters['siteConfigState/getBackend'];
    private static apiClient: any = axios.create({
        withCredentials: true,
    });
    private static config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    public static retrieveProductKey(data: Record<string, string | number | null>): AxiosPromise {
        return this.apiClient.post(`${this.host}/api/v2/product-key`, data, this.config);
    }
}
